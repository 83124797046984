 <!--
 * =====================================================================
 * 작 업 명  : 페이징 처리
 * 파 일 명  : dot-page.vue
 * 변경이력 
 * 2022-07-29 / 정태화 / 최초작성
 * =====================================================================
 -->
<template>
	<ul class="pagination">
		<li class="page-item">
			<a class="page-link" href="#" @click="first()">&lt;&lt;</a>
		</li>
		<li class="page-item">
			<a class="page-link" href="#" @click="prev()">&lt;</a>
		</li>
	
		<li v-for="i in END_PAGE" v-bind:key="i" :class="curPageClass(i)">
			<a class="page-link" href="#" @click="pageNum(i)" v-if="i>=START_PAGE">{{i}}</a>
		</li>
		<li class="page-item">
			<a class="page-link" href="#" @click="next()">&gt;</a>
		</li>
		<li class="page-item">
			<a class="page-link" href="#" @click="last()">&gt;&gt;</a>
		</li>
	</ul>
</template>
<script>
    export default 
	{
		props: ["pageNo", "pageSize", "totalCount", "blockSize"],
		data() 
		{
			return {
				PAGE_COUNT: 0,
				START_PAGE: 0,
				END_PAGE: 0
			}
		},
		watch: 
		{
			// props 데이타 변경에 따른 이벤트 처리
			totalCount()
			{
				// 페이징 처리 (데이타 변경시)
				this.pagingProcess();
			},
			pageNo()
			{
				// 페이징 처리 (데이타 변경시)
				this.pagingProcess();
			}
		},
		mounted() 
		{
			// 페이징 처리 (최초 바인딩시)
			this.pagingProcess();
		},		
		methods: 
        {
			// 처음 페이지
			first() 
			{
				this.$emit("pageClick", 1); // 이벤트 던지기
			},
			// 이전 페이지
			prev() 
			{
				let pageNo = this.pageNo - 1;
				this.pagingProcess();
				this.$emit("pageClick", pageNo < 1 ? 1 : pageNo); // 이벤트 던지기
			},
			// 페이지 숫자 표시
			pageNum(pageNo) 
			{
				this.pagingProcess();
				this.$emit("pageClick", pageNo); // 이벤트 던지기
			},
			// 다음 페이지
			next() 
			{
				let pageNo = this.pageNo + 1;
				let pageCount = this.PAGE_COUNT;
				this.pagingProcess();
				this.$emit("pageClick", pageNo > pageCount ? pageCount : pageNo); // 이벤트 던지기
			},
			// 마지막 페이지
			last() 
			{
				this.pagingProcess();
				this.$emit("pageClick", this.PAGE_COUNT); // 이벤트 던지기
			},
			// 현재 페이지 클래스 처리
			curPageClass(pageNo) 
			{
				if (pageNo == this.pageNo) {
					return "page-item active";
				} else {
					return "page-item";
				}
			},
			// 페이징 처리
			pagingProcess()
			{
				// PAGE_COUNT 설정
				var pageCount = parseInt(this.totalCount / this.pageSize);
				if (this.totalCount % this.pageSize > 0) {
					pageCount += 1;
				}
				this.PAGE_COUNT = pageCount;
				
				// START_PAGE 설정
				var blockSize = this.blockSize ? this.blockSize : 5;
				var curBlock = parseInt((this.pageNo - 1) / blockSize) + 1;
				this.START_PAGE = (curBlock - 1) * blockSize + 1;
				console.log(this.START_PAGE + "," + this.pageNo);
				
				// END_PAGE 설정
				var endPage = curBlock * blockSize;
				if(endPage > this.PAGE_COUNT) endPage = this.PAGE_COUNT;
				this.END_PAGE = endPage;
			}
		}
	}
</script>
<style>
	.pagination {
		display: -ms-flexbox;
		display: flex;
		padding-left: 0;
		list-style: none;
		border-radius: 0.25rem;
	}

	.page-link {
		position: relative;
		display: block;
		padding: 0.5rem 0.75rem;
		margin-left: -1px;
		line-height: 1.25;
		color: #007bff;
		background-color: #ffffff;
		border: 1px solid #dee2e6;
	}

	.page-link:hover {
		z-index: 2;
		color: #0056b3;
		text-decoration: none;
		background-color: #e9ecef;
		border-color: #dee2e6;
	}

	.page-link:focus {
		z-index: 2;
		outline: 0;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	.page-item:first-child .page-link {
		margin-left: 0;
		border-top-left-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}

	.page-item:last-child .page-link {
		border-top-right-radius: 0.25rem;
		border-bottom-right-radius: 0.25rem;
	}

	.page-item.active .page-link {
		z-index: 1;
		color: #ffffff;
		background-color: #007bff;
		border-color: #007bff;
	}

	.page-item.disabled .page-link {
		color: #6c757d;
		pointer-events: none;
		cursor: auto;
		background-color: #ffffff;
		border-color: #dee2e6;
	}

	.pagination-lg .page-link {
		padding: 0.75rem 1.5rem;
		font-size: 1.25rem;
		line-height: 1.5;
	}

	.pagination-lg .page-item:first-child .page-link {
		border-top-left-radius: 0.3rem;
		border-bottom-left-radius: 0.3rem;
	}

	.pagination-lg .page-item:last-child .page-link {
		border-top-right-radius: 0.3rem;
		border-bottom-right-radius: 0.3rem;
	}

	.pagination-sm .page-link {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.5;
	}

	.pagination-sm .page-item:first-child .page-link {
		border-top-left-radius: 0.2rem;
		border-bottom-left-radius: 0.2rem;
	}

	.pagination-sm .page-item:last-child .page-link {
		border-top-right-radius: 0.2rem;
		border-bottom-right-radius: 0.2rem;
	}
</style>