 <!--
 * =====================================================================
 * 작 업 명  : 페이징 처리 테스트
 * 파 일 명  : Pagination.vue
 * 변경이력 
 * 2022-07-29 / 정태화 / 최초작성
 * =====================================================================
 -->
<template>
	<div>
		<dot-pagination 
			:pageNo="pageNo" 
			:pageSize="pageSize"
			:totalCount="totalCount"
			@pageClick="pageClick">
		</dot-pagination>
		<input type="text" v-model="totalCount">
		<button>전체 갯수 변경</button>
	</div>
</template>
<script>
	import dotPagination from '../../components/dot-ui/dot-pagination.vue';
	export default 
	{
		components: 
		{ 
			dotPagination 
		},
		data() 
		{
			return {
				pageNo: 1,
				pageSize: 10,
				totalCount: 15,
			}
		},
		methods: 
        {
			// 페이지번호 클릭
			pageClick(pageNo)
			{
				this.pageNo = pageNo;
				alert(pageNo + " 페이지를 클릭했습니다.");
			}
		}
	}
</script>
<style>

</style>
